.recipe-container {
    display: flex;
    justify-content: flex-start;
    /* flex-direction: row;
    flex-wrap: nowrap; */
} 

.container {
    display: flex;
    flex-direction: column;
    margin: 30px 70px 10px 70px;
    width: 100%;
} 
 
p {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 24px;
    margin-right: 10px;
}

h1 {
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 40px;
    margin-right: 10px;
    text-align: center;
}

.edit {
    margin-top: 50px;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #F7B27D;
    border: none;
    cursor: pointer;
    font-size: 20px;
    width: fit-content;
}

.edit:hover {
    background-color: #ffd5b6;
}

.button-container {
    display: flex;
    margin-bottom: 50px;
  }

.approved {
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    width: fit-content;
    border: none;
    cursor: pointer;
    background-color: rgb(58, 172, 58);
    font-size: 20px;
    color: #ffffff;
    font-weight: 30;
}

.approved:hover {
    background-color: rgb(120, 220, 120);
    color: black
}

.declined {
    margin-top: 10px;
    margin-left: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    width: fit-content;
    border: none;
    cursor: pointer;
    background-color: rgb(226, 70, 70);
    font-size: 20px;
    color: #ffffff;
    font-weight: 30;
}

.declined:hover {
    background-color: rgb(255, 138, 138);
    color: black
}

.change {
    margin-top: 50px;
    font-size: 28px;
}

.info-header {
    font-weight: bold;
    
}

.info-details {
    background-color: #EFEBE7;
    padding: 10px;
}
  
