.outerContainer
{
    display: flex;
    justify-content: flex-start;
}

.innerContainer
{
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 100%;
}

h1
{
	text-align: center;
	font-size: 50px;
	text-align: center;
}
