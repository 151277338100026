form {
    display: flex;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 100px;
    /* position: absolute; */
}

.EditRecipe-Container {
    display: flex;
    justify-content: flex-start;
    /* flex-direction: row;
    flex-wrap: nowrap; */
}

label {
    margin-top: 5px;
    font-weight: bold;
    font-size: 25px;
}

.sub-label {
    font-size: 20px;
    color: #4b3286;
    width:fit-content;
}

select, textarea {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
    width: 50%;
    border-radius: 5px;
    font-size: 15px;
    margin-right: 10px;
}

input {
    /* display: flex; */
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 20px;
    margin-right: 10px;
}


h1 {
    text-align: center;
}

.submit {
    padding: 8px 16px;
    border-radius: 10px;
    background-color: #F7B27D;
    border: none;
    cursor: pointer;
    width: 100px;
}

.submit:hover {
    background-color: #ffd5b6;
}
  
.success-banner {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
}