.user-container {
    display: flex;
    justify-content: flex-start;
}

.container {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 100%;

}

h1 {
    text-align: center;
}

#title {
    text-align: center;
  }
  
  #recipes {
    text-align: center;
    border-collapse: collapse;
    border: 3px solid black;
    width: 100%;
  }
  
  #recipes td, #recipes th {
    border: 1px solid #000;
    padding: 8px;
  }
  
  #recipes tr:nth-child(even){background-color: #f2f2f2;}
  
  #recipes tr:hover {background-color: #ddd;}
  
  #recipes th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #EFEBE7;
    color: black;
  }