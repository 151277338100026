.ManageStudents-Container {
    display: flex;
    justify-content: flex-start;
    /* flex-direction: row;
    flex-wrap: nowrap; */
} 


.conatiner {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 100%;
} 
 
.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .integer-input {
    display: flex;
    flex-direction: column;
    /* Reset the default styling for number inputs */
    -moz-appearance: textfield;
    appearance: textfield;
    /* Set the width, padding, and border to match a regular text box */
    width: 500px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* Ensure the cursor appears as a text cursor */
    cursor: text;
  }

  /* Remove the increment and decrement arrows */
  .integer-input::-webkit-inner-spin-button,
  .integer-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  

  label {
    margin-bottom: 5px;
  }
  
  input[type='text'] {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 500px;
  }
  
  .button {
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #F7B27D;
    border: none;
    cursor: pointer;
    width: fit-content;

  }
  
  .button:hover {
    background-color: #ffd5b6;
  }
  
.view-all-button {
  padding: 10px 30px;
  border-radius: 5px;
  background-color: #9fa186;
  border: none;
  cursor: pointer;
  max-width: fit-content;
  font-size: 20px;
  margin-top: 50px;
  text-align: center;
}

.view-all-button:hover {
  background-color: #B7B9A4;
  text-decoration: none;
  color: #000000;
}

.success-banner {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}