body{
    margin: 0;
}

.headerContainer {
    min-height: 100vh;
    min-width: 300px;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #f4f0edff;
    overflow-x: hidden;
    padding-top: 20px;
    text-align: center;
}
  
  ul {
    list-style-type: none;
    margin-top: 0;
    padding: 0;
    margin-left: 30px;
  }
  
  li {
    margin-bottom: 25px;
    margin-right: 50px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
a {
  color: black; 
  font-size: 28px; 
  font-weight: 200; 
  word-wrap: break-word;
  text-decoration: none;
}
  
a:hover {
  color: #877b60;
  text-decoration: underline;
}

.userName {
  position:fixed;
  min-width: 300px;
  bottom: 0;
  background-color: #f4f0edff;
  text-align: center;
  justify-content: center;
  color: #877b60;
}