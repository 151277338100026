.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f0edff;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .login-form label {
    font-weight: bold;
  }
  
  .login-form input {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 350px;
  }
  
  .login-button {
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #F7B27D;
    border: none;
    cursor: pointer;
    width: fit-content;
    font-size: 20px;
  }

  .login-button:hover {
    background-color: #ffd5b6;
  }

  .errmsg {
    background-color: #cc4242;
    color: #ffffff;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

input {
  font-size: 20px;
  
}
  
