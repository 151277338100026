.allStudents-Container {
    display: flex;
    justify-content: flex-start;
    /* flex-direction: row;
    flex-wrap: nowrap; */
}

.container {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 100%;
}

h1 {
  text-align: center;
}

#title {
    text-align: center;
  }
  
  #students {
    text-align: center;
    border-collapse: collapse;
    border: 3px solid black;
    width: 100%;
  }
  
  #students td, #students th {
    border: 1px solid #000;
    padding: 8px;
  }
  
  #students tr:nth-child(even){background-color: #f2f2f2;}
  
  /* #students tr:hover {background-color: #ddd;} */
  
  #students th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #EFEBE7;
    color: black;
  }

  .manage-button {
    padding: 10px 30px;
    border-radius: 5px;
    background-color: #9fa186;
    border: none;
    cursor: pointer;
    width: fit-content;
    font-size: 20px;
    margin-top: 50px;
    text-align: center;
  }
  
  .manage-button:hover {
    background-color: #B7B9A4;
    text-decoration: none;
    color: #000000;
  }
  
  .studentTable {
    font-size: 24px;
  }